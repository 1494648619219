// extracted by mini-css-extract-plugin
export var active = "policy-module--active--7f949";
export var heroBackground = "policy-module--heroBackground--15bbd";
export var heroWrap = "policy-module--heroWrap--8fd63";
export var lastUpdateDate = "policy-module--lastUpdateDate--3308f";
export var menuWrapper = "policy-module--menuWrapper--cc3c1";
export var postContentMenu = "policy-module--postContentMenu--c81fc";
export var privacyDescription = "policy-module--privacyDescription--723cd";
export var privacyHeroMainTitle = "policy-module--privacyHeroMainTitle--195ea";
export var privacyPageHero = "policy-module--privacyPageHero--6fe12";
export var privacyWrapper = "policy-module--privacyWrapper--df6cb";